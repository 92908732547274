
let DataPage = {
    "btn": [],
    "site": [],
    "settings": {},
    "slide_show": []
};

let NotifyDataPage = {
    "btn": [],
    "site": [],
    "settings": [],
    "slide_show": []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["btn"].forEach(element => { element(value['btn']); });
    NotifyDataPage["site"].forEach(element => { element(value['site']); });
    NotifyDataPage["settings"].forEach(element => { element(value['settings']); });
    NotifyDataPage["slide_show"].forEach(element => { element(value['slide_show']); });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}

export function UnRegisterDataPage(key, callback){
    if(NotifyDataPage[key].length > 0){
        NotifyDataPage[key] = NotifyDataPage[key].filter((item) => {
            return item !== callback;
        });
    }
}