import React, { useState, useEffect } from "react";

import './DetailsPurchase.css';

import { GetDataPage } from "interface/Data";
import { SetListPurchaseSingle } from "interface/Purchase";
import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { Svg_Arrow, Svg_Close, Svg_Purchase } from "services/SvgFile";

export default function PopUP_DetailsPurchase(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ showColor, setShowCoolor ] = useState(GetDataPage('settings'));

    const [ purchase, setPurchase ] = useState({});
    const [ listPurchase, setListPurchase ] = useState([]);

    function ClosePopUp(){
        document.body.style.overflow = 'auto';
        
        SetModalData('DetailsPurchase', {});
        SetModalState('DetailsPurchase', false);
    }

    useEffect(()=>{
        RegisterModalData('DetailsPurchase', setModaldata);
        RegisterModalObserver('DetailsPurchase', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            document.body.style.overflow = 'hidden';
            
            setPurchase(modalData);
            setListPurchase(modalData['list']);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <div className="PopUp">
                <div className="all DetailsPurchase">
                    <div className="div_topic">
                        <div className="title">Dados da compra</div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close color="#f00000" className="icons" />
                        </div>
                    </div>
                    <div className="div_content">
                        <div className="div_inf_purchase">
                            <div className=""><b>Data da compra:</b> { purchase['register_date'] }</div>
                            <div className=""><b>Valor total:</b> R$ { purchase['price'] }</div>
                        </div>
                        <div className="div_inf_purchase">
                            <div className=""><b>Quatidade de item:</b> { purchase['qtd_item'] }</div>
                            <div className=""><b>Pagamento:</b> { purchase['status'] }</div>
                        </div>
                        <div className="div_list">
                            {
                                listPurchase.map((elem, index)=>{
                                    let img = '';
                                    let size = '';
                                    let infProduct = GetDataPage('site').find(item => item.origin == 'product');
                                    let dataProduct = '';
                                    if(infProduct){
                                        let showDataProduct = infProduct['contents'].find(item => item.id == elem.id_product);
                                        dataProduct = showDataProduct;
                                        size = showDataProduct['size'];
                                        let showDataImg =  showDataProduct['gallery'].find(item => item.highlight == true);
                                        if(showDataImg){
                                            img = showDataImg['file'];
                                        }else {
                                            if(showDataProduct['gallery'].length > 0){
                                                img = showDataProduct['gallery'][0]['file'];
                                            }
                                        }
                                    }

                                    return(          
                                        <div className="inf_product" key={ index }>  
                                            <div className="div_img" style={ { backgroundImage: 'url("' + img + '")' } } /> 
                                        
                                            <div className="details_product">
                                                <div className="title_product">{ elem.title }</div>
                                                <div className="subtitle_product">{ elem.subtitle }</div>
                                                <div className="div_inf_purchase">
                                                    <div className="div_input">
                                                        <div className="">Tamanho</div>
                                                        <input type="text" value={ size } readOnly />
                                                    </div>
                                                    <div className="div_input">
                                                        <div className="">
                                                            Valor 
                                                            {
                                                                elem.discount_price !='' ?
                                                                    <span className="discount_price"> R$ { elem.price }</span>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        <input type="text" className="price_input" value={ elem.discount_price == "" ? elem.price : elem.discount_price } readOnly />
                                                    </div>
                                                    <div className="div_input">
                                                        <div className="">Qts</div>
                                                        <input type="text" className="qtd" value={ elem.qtd } readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
        : <React.Fragment></React.Fragment>)
    );
}
