export function Svg_Close(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M18 6L6 18" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 6L18 18" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function Svg_Erro(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
        </svg>
    )
}

export function Svg_ChekedReturn(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" />
        </svg>
    )
}

export function Svg_Whatsapp(props){
    return(
        <svg viewBox="0 0 25 25" className={ props.className } fill="none">
            <path fill={ props.color } d="M20.1611 4.74121C18.1152 2.69043 15.3906 1.5625 12.4951 1.5625C6.51855 1.5625 1.65527 6.42578 1.65527 12.4023C1.65527 14.3115 2.15332 16.1768 3.10059 17.8223L1.5625 23.4375L7.30957 21.9287C8.8916 22.793 10.6738 23.2471 12.4902 23.2471H12.4951C18.4668 23.2471 23.4375 18.3838 23.4375 12.4072C23.4375 9.51172 22.207 6.79199 20.1611 4.74121ZM12.4951 21.4209C10.874 21.4209 9.28711 20.9863 7.90527 20.166L7.57812 19.9707L4.16992 20.8643L5.07812 17.5391L4.86328 17.1973C3.95996 15.7617 3.48633 14.1064 3.48633 12.4023C3.48633 7.43652 7.5293 3.39355 12.5 3.39355C14.9072 3.39355 17.168 4.33105 18.8672 6.03516C20.5664 7.73926 21.6113 10 21.6064 12.4072C21.6064 17.3779 17.4609 21.4209 12.4951 21.4209ZM17.4365 14.6729C17.168 14.5361 15.835 13.8818 15.5859 13.7939C15.3369 13.7012 15.1563 13.6572 14.9756 13.9307C14.7949 14.2041 14.2773 14.8096 14.1162 14.9951C13.96 15.1758 13.7988 15.2002 13.5303 15.0635C11.9385 14.2676 10.8936 13.6426 9.84375 11.8408C9.56543 11.3623 10.1221 11.3965 10.6396 10.3613C10.7275 10.1807 10.6836 10.0244 10.6152 9.8877C10.5469 9.75098 10.0049 8.41797 9.78027 7.87598C9.56055 7.34863 9.33594 7.42188 9.16992 7.41211C9.01367 7.40234 8.83301 7.40234 8.65234 7.40234C8.47168 7.40234 8.17871 7.4707 7.92969 7.73926C7.68066 8.0127 6.98242 8.66699 6.98242 10C6.98242 11.333 7.9541 12.6221 8.08594 12.8027C8.22266 12.9834 9.99512 15.7178 12.7148 16.8945C14.4336 17.6367 15.1074 17.7002 15.9668 17.5732C16.4893 17.4951 17.5684 16.9189 17.793 16.2842C18.0176 15.6494 18.0176 15.1074 17.9492 14.9951C17.8857 14.873 17.7051 14.8047 17.4365 14.6729Z" />
        </svg>
    )
}

export function Svg_Email(props){
    return(
        <svg viewBox="0 0 25 25" className={ props.className } fill="none">
            <path fill={ props.color } d="M22.6562 3.125H2.34375C1.04932 3.125 0 4.17432 0 5.46875V19.5312C0 20.8257 1.04932 21.875 2.34375 21.875H22.6562C23.9507 21.875 25 20.8257 25 19.5312V5.46875C25 4.17432 23.9507 3.125 22.6562 3.125ZM22.6562 5.46875V7.46118C21.5614 8.35273 19.816 9.73906 16.0846 12.6609C15.2623 13.3077 13.6334 14.8617 12.5 14.8436C11.3668 14.8619 9.73735 13.3075 8.91538 12.6609C5.18457 9.7395 3.43872 8.35288 2.34375 7.46118V5.46875H22.6562ZM2.34375 19.5312V10.4687C3.4626 11.3598 5.04927 12.6104 7.46768 14.5041C8.53491 15.3442 10.4039 17.1987 12.5 17.1875C14.5858 17.1987 16.4311 15.3711 17.5319 14.5045C19.9502 12.6108 21.5374 11.3599 22.6562 10.4687V19.5312H2.34375Z" />
        </svg>
    )
}

export function Svg_Facebook(props){
    return(
        <svg viewBox="0 0 20 20" className={ props.className } fill="none">
            <path fill={ props.color } d="M19.6875 10C19.6875 4.64844 15.3516 0.3125 10 0.3125C4.64844 0.3125 0.3125 4.64844 0.3125 10C0.3125 14.8352 3.85508 18.843 8.48633 19.5703V12.8004H6.02539V10H8.48633V7.86562C8.48633 5.43789 9.93164 4.09687 12.1453 4.09687C13.2055 4.09687 14.3141 4.28594 14.3141 4.28594V6.66875H13.0922C11.8891 6.66875 11.5137 7.41562 11.5137 8.18164V10H14.2004L13.7707 12.8004H11.5137V19.5703C16.1449 18.843 19.6875 14.8352 19.6875 10Z" />
        </svg>
    )
}

export function Svg_Facebook_1(props){
    return(
        <svg viewBox="0 0 11 21" className={ props.className } fill="none">
            <path fill={ props.color } d="M8.51921 3.46066H10.4219V0.146763C10.0937 0.101605 8.96471 0 7.64992 0C4.90658 0 3.02732 1.72555 3.02732 4.89703V7.81579H0V11.5205H3.02732V20.8421H6.73895V11.5213H9.64382L10.1049 7.81666H6.73808V5.26437C6.73895 4.1936 7.02726 3.46066 8.51921 3.46066Z" />
        </svg>
    )
}

export function Svg_Instagram(props){
    return(
        <svg viewBox="0 0 20 20" className={ props.className } fill="none">
            <path fill={ props.color } d="M10.0039 5.50781C7.51953 5.50781 5.51562 7.51172 5.51562 9.99609C5.51562 12.4805 7.51953 14.4844 10.0039 14.4844C12.4883 14.4844 14.4922 12.4805 14.4922 9.99609C14.4922 7.51172 12.4883 5.50781 10.0039 5.50781ZM10.0039 12.9141C8.39844 12.9141 7.08594 11.6055 7.08594 9.99609C7.08594 8.38672 8.39453 7.07812 10.0039 7.07812C11.6133 7.07812 12.9219 8.38672 12.9219 9.99609C12.9219 11.6055 11.6094 12.9141 10.0039 12.9141ZM15.7227 5.32422C15.7227 5.90625 15.2539 6.37109 14.6758 6.37109C14.0938 6.37109 13.6289 5.90234 13.6289 5.32422C13.6289 4.74609 14.0977 4.27734 14.6758 4.27734C15.2539 4.27734 15.7227 4.74609 15.7227 5.32422ZM18.6953 6.38672C18.6289 4.98438 18.3086 3.74219 17.2812 2.71875C16.2578 1.69531 15.0156 1.375 13.6133 1.30469C12.168 1.22266 7.83594 1.22266 6.39062 1.30469C4.99219 1.37109 3.75 1.69141 2.72266 2.71484C1.69531 3.73828 1.37891 4.98047 1.30859 6.38281C1.22656 7.82812 1.22656 12.1602 1.30859 13.6055C1.375 15.0078 1.69531 16.25 2.72266 17.2734C3.75 18.2969 4.98828 18.6172 6.39062 18.6875C7.83594 18.7695 12.168 18.7695 13.6133 18.6875C15.0156 18.6211 16.2578 18.3008 17.2812 17.2734C18.3047 16.25 18.625 15.0078 18.6953 13.6055C18.7773 12.1602 18.7773 7.83203 18.6953 6.38672ZM16.8281 15.1562C16.5234 15.9219 15.9336 16.5117 15.1641 16.8203C14.0117 17.2773 11.2773 17.1719 10.0039 17.1719C8.73047 17.1719 5.99219 17.2734 4.84375 16.8203C4.07812 16.5156 3.48828 15.9258 3.17969 15.1562C2.72266 14.0039 2.82813 11.2695 2.82813 9.99609C2.82813 8.72266 2.72656 5.98438 3.17969 4.83594C3.48438 4.07031 4.07422 3.48047 4.84375 3.17187C5.99609 2.71484 8.73047 2.82031 10.0039 2.82031C11.2773 2.82031 14.0156 2.71875 15.1641 3.17187C15.9297 3.47656 16.5195 4.06641 16.8281 4.83594C17.2852 5.98828 17.1797 8.72266 17.1797 9.99609C17.1797 11.2695 17.2852 14.0078 16.8281 15.1562Z" />
        </svg>
    )
}

export function Svg_Youtube(props){
    return(
        <svg viewBox="0 0 20 20" className={ props.className } fill="none">
            <path fill={ props.color } d="M19.0852 5.41971C18.8672 4.59853 18.2246 3.95179 17.4087 3.73232C15.9299 3.3335 10 3.3335 10 3.3335C10 3.3335 4.07014 3.3335 2.59129 3.73232C1.77542 3.95183 1.13285 4.59853 0.914759 5.41971C0.518509 6.90815 0.518509 10.0136 0.518509 10.0136C0.518509 10.0136 0.518509 13.1191 0.914759 14.6076C1.13285 15.4287 1.77542 16.0485 2.59129 16.268C4.07014 16.6668 10 16.6668 10 16.6668C10 16.6668 15.9299 16.6668 17.4087 16.268C18.2246 16.0485 18.8672 15.4287 19.0852 14.6076C19.4815 13.1191 19.4815 10.0136 19.4815 10.0136C19.4815 10.0136 19.4815 6.90815 19.0852 5.41971ZM8.06059 12.8332V7.19409L13.0168 10.0137L8.06059 12.8332Z" />
        </svg>
    )
}

export function Svg_Tiktok(props){
    return(
        <svg viewBox="0 0 20 20" className={ props.className } fill="none">
            <path fill={ props.color } d="M16.1012 4.63516C15.9746 4.56975 15.8514 4.49804 15.732 4.42031C15.3849 4.1908 15.0666 3.92037 14.784 3.61484C14.0769 2.80586 13.8129 1.98516 13.7156 1.41055H13.7195C13.6383 0.933594 13.6719 0.625 13.6769 0.625H10.4566V13.0773C10.4566 13.2445 10.4566 13.4098 10.4496 13.573C10.4496 13.5934 10.4476 13.6121 10.4465 13.634C10.4465 13.643 10.4465 13.6523 10.4445 13.6617C10.4445 13.6641 10.4445 13.6664 10.4445 13.6688C10.4106 14.1155 10.2674 14.5471 10.0275 14.9256C9.78755 15.304 9.45833 15.6177 9.06874 15.8391C8.6627 16.0701 8.20346 16.1912 7.73632 16.1906C6.23593 16.1906 5.01991 14.9672 5.01991 13.4563C5.01991 11.9453 6.23593 10.7219 7.73632 10.7219C8.02033 10.7216 8.3026 10.7663 8.57265 10.8543L8.57655 7.57539C7.75677 7.4695 6.92394 7.53465 6.1306 7.76673C5.33726 7.99882 4.60063 8.3928 3.96718 8.92383C3.41212 9.40609 2.94549 9.98152 2.58827 10.6242C2.45233 10.8586 1.93944 11.8004 1.87733 13.3289C1.83827 14.1965 2.09882 15.0953 2.22304 15.4668V15.4746C2.30116 15.6934 2.60389 16.4398 3.09725 17.0691C3.49508 17.5739 3.9651 18.0174 4.49218 18.3852V18.3773L4.49999 18.3852C6.05897 19.4445 7.78749 19.375 7.78749 19.375C8.08671 19.3629 9.08905 19.375 10.2273 18.8355C11.4898 18.2375 12.2086 17.3465 12.2086 17.3465C12.6678 16.8141 13.0329 16.2074 13.2883 15.5523C13.5797 14.7863 13.6769 13.8676 13.6769 13.5004V6.89414C13.716 6.91758 14.2363 7.26172 14.2363 7.26172C14.2363 7.26172 14.9859 7.74219 16.1555 8.05508C16.9945 8.27773 18.125 8.32461 18.125 8.32461V5.12773C17.7289 5.1707 16.9246 5.0457 16.1012 4.63516Z" />
        </svg>
    )
}

export function Svg_Linkedin(props){
    return(
        <svg viewBox="0 0 20 20" className={ props.className } fill="none">
            <path fill={ props.color } d="M17.5 1.25H2.49609C1.80859 1.25 1.25 1.81641 1.25 2.51172V17.4883C1.25 18.1836 1.80859 18.75 2.49609 18.75H17.5C18.1875 18.75 18.75 18.1836 18.75 17.4883V2.51172C18.75 1.81641 18.1875 1.25 17.5 1.25ZM6.53906 16.25H3.94531V7.89844H6.54297V16.25H6.53906ZM5.24219 6.75781C4.41016 6.75781 3.73828 6.08203 3.73828 5.25391C3.73828 4.42578 4.41016 3.75 5.24219 3.75C6.07031 3.75 6.74609 4.42578 6.74609 5.25391C6.74609 6.08594 6.07422 6.75781 5.24219 6.75781ZM16.2617 16.25H13.668V12.1875C13.668 11.2188 13.6484 9.97266 12.3203 9.97266C10.9688 9.97266 10.7617 11.0273 10.7617 12.1172V16.25H8.16797V7.89844H10.6562V9.03906H10.6914C11.0391 8.38281 11.8867 7.69141 13.1484 7.69141C15.7734 7.69141 16.2617 9.42188 16.2617 11.6719V16.25Z" />
        </svg>
    )
}

export function Svg_User(props){
    return(
        <svg viewBox="0 0 45 45" className={ props.className } fill="none">
            <circle cx="22.5" cy="22.5" r="21.5" stroke={ props.color } strokeWidth="2"/>
            <path d="M31.4853 25.5147C30.1783 24.2077 28.6226 23.2402 26.9253 22.6545C28.7432 21.4024 29.9375 19.3069 29.9375 16.9375C29.9375 13.1122 26.8253 10 23 10C19.1747 10 16.0625 13.1122 16.0625 16.9375C16.0625 19.3069 17.2568 21.4024 19.0748 22.6545C17.3774 23.2402 15.8218 24.2077 14.5147 25.5147C12.2482 27.7812 11 30.7947 11 34H12.875C12.875 28.417 17.417 23.875 23 23.875C28.583 23.875 33.125 28.417 33.125 34H35C35 30.7947 33.7518 27.7812 31.4853 25.5147ZM23 22C20.2085 22 17.9375 19.729 17.9375 16.9375C17.9375 14.146 20.2085 11.875 23 11.875C25.7915 11.875 28.0625 14.146 28.0625 16.9375C28.0625 19.729 25.7915 22 23 22Z" fill={ props.color }/>
        </svg>
    )
}

export function Svg_Purchase(props){
    return(
        <svg viewBox="0 0 31 36" className={ props.className } fill="none">
            <path fill={ props.color } d="M30.488 33.7788L28.2452 10.5694C28.184 9.93553 27.6123 9.45004 26.9276 9.45004H22.3025V7.51167C22.3025 3.89914 19.1398 0.960114 15.2524 0.960114C11.3651 0.960114 8.2028 3.89914 8.2028 7.51167V9.45004H3.57453C2.88978 9.45004 2.31811 9.93553 2.2569 10.5694L0.00528972 33.8701C-0.0278715 34.214 0.0959537 34.5548 0.346603 34.8095C0.597252 35.0642 0.951618 35.2093 1.32292 35.2093H29.1794C29.1804 35.2093 29.1818 35.2093 29.1829 35.2093C29.9137 35.2093 30.5058 34.6589 30.5058 33.98C30.5056 33.9116 30.4996 33.8442 30.488 33.7788ZM10.8486 7.51167C10.8486 5.25482 12.8242 3.41873 15.2526 3.41873C17.6811 3.41873 19.6568 5.25482 19.6568 7.51167V9.45004H10.8486V7.51167ZM2.77001 32.7508L4.78403 11.9087H8.2028V14.107C8.2028 14.7859 8.79494 15.3363 9.52572 15.3363C10.2565 15.3363 10.8486 14.7859 10.8486 14.107V11.9087H19.6568V14.107C19.6568 14.7859 20.249 15.3363 20.9797 15.3363C21.7105 15.3363 22.3027 14.7859 22.3027 14.107V11.9087H25.7183L27.7323 32.7508H2.77001Z" />
        </svg>
    )
}

export function Svg_Search(props){
    return(
        <svg viewBox="0 0 28 28" className={ props.className } fill="none">
            <path fill={ props.color } d="M16.6359 0C10.3698 0 5.27187 5.09791 5.27187 11.3641C5.27187 14.2023 6.31783 16.8009 8.04448 18.7946L0.240242 26.5997C-0.0801172 26.92 -0.0800625 27.4395 0.240297 27.7598C0.560656 28.0802 1.08002 28.0801 1.40038 27.7598L9.20451 19.9548C11.1984 21.6819 13.7973 22.7281 16.6359 22.7281C22.9021 22.7281 28 17.6302 28 11.3641C28 5.09791 22.9021 0 16.6359 0ZM16.6359 21.0875C11.2744 21.0875 6.9125 16.7256 6.9125 11.3641C6.9125 6.00255 11.2744 1.64062 16.6359 1.64062C21.9974 1.64062 26.3594 6.00255 26.3594 11.3641C26.3594 16.7256 21.9974 21.0875 16.6359 21.0875Z" />
        </svg>
    )
}

export function Svg_Arrow(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path fill={ props.color } d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"/>
        </svg>
    )
}

export function Svg_AddNewData(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2"/>
            <line x1="12" y1="8" x2="12" y2="16"/>
            <line x1="8" y1="12" x2="16" y2="12"/>
        </svg>
    )
}

export function Svg_Menu(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"/>
        </svg>
    )
}