import { useState, useEffect } from "react";

import './Btn.css';

import { GetDataPage } from "interface/Data";

export default function Comp_Btn(props){

    const [ showData, setShowData ] = useState(GetDataPage('settings'));
    const [ showBtn, setShowBtn ] = useState(GetDataPage('btn'));

    return(
        showBtn.length > 0 ?
            <div className="Btn" style={ { background: showData['default_color'] } }>
                <div className={ "container show_btn_" + showBtn.length }>
                    {
                        showBtn.map((elem, index)=>{
                            return(
                                <div className="show_btn" key={ index }>
                                    <div className="div_icon">
                                        <img alt={ "img_" + index } src={ elem.file } className="icons" />
                                    </div>
                                    <div className="text title">{ elem.title }</div>
                                    <div className="text subtitle">{ elem.subtitle }</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        : null
    )
}