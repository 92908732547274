import { useState, useEffect } from "react";

import './Banner.css';

export default function Content_Banner(props){

    function CheckedTypeBanner(){
        if(props.data['banner_file_1'] !='' && props.data['banner_file_2'] !=''){            
            return(
                <div className="container">
                    <img alt="banner" src={ props.data['banner_file_1'] } className="two_banners" />
                    <img alt="banner" src={ props.data['banner_file_2'] } className="two_banners" />
                </div>
            )

        }else if(props.data['banner_file_1'] !='' && props.data['banner_file_2'] == ''){
            return(
                <img alt="banner" src={ props.data['banner_file_1'] } className="one_banner" />
            )

        }else if(props.data['banner_file_1'] == '' && props.data['banner_file_2'] !=''){
            return(
                <img alt="banner" src={ props.data['banner_file_2'] } className="one_banner" />
            )
        }
    }

    return(
        <div className="Banner">
            { CheckedTypeBanner() }
        </div>
    )
}