import Axios from 'axios';

import Cookies from 'universal-cookie';

import { cookieExpirationTime, cookiesRegister, typeCookieEmail, typeCookiePassw, urlPhp } from 'fixedData';

import { SetUserData } from 'interface/Users';
import { SetListPurchaseSingle } from 'interface/Purchase';
import { SetListPag } from 'interface/Page';

export function Access(type, reg_email, reg_pass, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_post', 'logar');
    data.append('type', type);
    data.append('email', reg_email);
    data.append('password', reg_pass);

    Axios({
        url : urlPhp + 'inc/login.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        switch(response.data.type) {
            case 'Connected':
                    CallbackSuccess();                    
                    cookies.set(typeCookieEmail, response.data.email, { path: '/', expires: cookieExpirationTime, domain: cookiesRegister });
                    cookies.set(typeCookiePassw, response.data.pass, { path: '/', expires: cookieExpirationTime, domain: cookiesRegister });

                    SetUserData('id', response.data.id);
                    SetUserData('file', response.data.file);
                    SetUserData('name', response.data.name);
                    SetUserData('email', response.data.email);
                    SetUserData('cep', response.data.cep);
                    SetUserData('street', response.data.street);
                    SetUserData('state', response.data.state);
                    SetUserData('city', response.data.city);
                    SetUserData('purchase', response.data.purchase);
                    SetUserData('complement', response.data.complement);
                    SetUserData('phone', response.data.phone);
                    SetUserData('number', response.data.number);
                    SetUserData('neighborhood', response.data.neighborhood);
                break;

            default:
                    CallbackError();
                    cookies.remove(typeCookieEmail, "", '/', cookiesRegister);
                    cookies.remove(typeCookiePassw, "", '/', cookiesRegister);
                break;
        }

    }).catch((error)=>{
        CallbackError();
        cookies.remove(typeCookieEmail, "", '/', cookiesRegister);
        cookies.remove(typeCookiePassw, "", '/', cookiesRegister);
    })
}

export function Register(idUser, name, email, password_1, complement, phone, number, neighborhood, cep, street, state, city, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_post', 'register_user');
    data.append('idUser', idUser);
    data.append('name', name);
    data.append('email', email);
    data.append('password', password_1);
    data.append('complement', complement);
    data.append('phone', phone);
    data.append('cep', cep);
    data.append('street', street);
    data.append('state', state);
    data.append('city', city);
    data.append('number', number);
    data.append('neighborhood', neighborhood);

    Axios({
        url : urlPhp + 'inc/login.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        switch(response.data.type) {
            case 'Connected':
                    CallbackSuccess();                    
                    cookies.set(typeCookieEmail, response.data.email, { path: '/', expires: cookieExpirationTime, domain: cookiesRegister });
                    if(response.data.pass !=''){
                        cookies.set(typeCookiePassw, response.data.pass, { path: '/', expires: cookieExpirationTime, domain: cookiesRegister });
                    }

                    SetUserData('id', response.data.id);
                    SetUserData('file', response.data.file);
                    SetUserData('name', response.data.name);
                    SetUserData('email', response.data.email);
                    SetUserData('cep', response.data.cep);
                    SetUserData('street', response.data.street);
                    SetUserData('state', response.data.state);
                    SetUserData('city', response.data.city);
                    SetUserData('purchase', response.data.purchase);
                    SetUserData('complement', response.data.complement);
                    SetUserData('phone', response.data.phone);
                    SetUserData('number', response.data.number);
                    SetUserData('neighborhood', response.data.neighborhood);
                break;

            default:
                    CallbackError();
                    cookies.remove(typeCookieEmail, "", '/', cookiesRegister);
                    cookies.remove(typeCookiePassw, "", '/', cookiesRegister);
                break;
        }

    }).catch((error)=>{
        CallbackError();
        cookies.remove(typeCookieEmail, "", '/', cookiesRegister);
        cookies.remove(typeCookiePassw, "", '/', cookiesRegister);
    })
}

export function RegisterPurchase(idUser, listPurchase, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_page', 'register_purchase');
    data.append('idUser', idUser);

    listPurchase.forEach((elem, index) => {
        data.append('purchase_id[]', elem.id);
        data.append('purchase_price[]', elem.price);
        data.append('purchase_discount_price[]', elem.discount_price);
        data.append('purchase_qtd_purchase[]', elem.qtd_purchase);
        data.append('purchase_title[]', elem.title);
        data.append('purchase_subtitle[]', elem.subtitle);
    });    

    Axios({
        url : urlPhp + 'inc/purchase.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        switch(response.data.type) {
            case 'Connected':                    
                    CallbackSuccess();
                    SetListPag('page', 'dashboard');
                    SetListPurchaseSingle('remuveAll', 0, '');
                break;

            default:
                    CallbackError();
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}