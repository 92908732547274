import { useState, useEffect } from "react";

import './Faq.css';

import { Svg_Arrow } from "services/SvgFile";

import { GetDataPage } from "interface/Data";

export default function Content_Faq(props){
    
    const [ showData, setShowData ] = useState(GetDataPage('settings'));
    const [ showFaq, setShowFaq ] = useState(props.data);
    
    function OpenDoubts(id, index, status){
        const newData = [...showFaq];
        newData[index]['status'] = status;
        setShowFaq(newData);

        let divResponse = document.getElementById(id);
        if(divResponse){            
            if(status){
                divResponse.style.maxHeight = (divResponse.scrollHeight + 16) + "px";
            }else {
                divResponse.style.maxHeight = null;
            }
        }
    }

    return(
        <div className="Faq" style={ { background: showData['default_color'] } }>
            <div className="container">
                <div className="title">Tire suas dúvidas</div>
                {
                    showFaq.map((elem, index)=>{
                        return(
                            <div className="div_list_data" key={ index }>
                                <div className="question" onClick={ ()=>{ OpenDoubts("response_" + index, index, !elem.status) } }>
                                    <div className="">{ elem.question }</div>
                                    <div className="">
                                        <Svg_Arrow className={ elem.status ? "icons icons_active" : "icons" } color="#ffffff" />
                                    </div>
                                </div>
                                <div className="response" id={ "response_" + index } dangerouslySetInnerHTML={ { __html: elem.response.replaceAll('&#34;', '"') } } />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}