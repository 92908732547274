import React, { useState, useEffect } from "react";

import './ShowText.css';

import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { Svg_Close } from "services/SvgFile";

export default function PopUP_ShowText(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    function ClosePopUp(){
        document.body.style.overflow = 'auto';

        SetModalData('ShowText', {});
        SetModalState('ShowText', false);
    }

    useEffect(()=>{
        RegisterModalData('ShowText', setModaldata);
        RegisterModalObserver('ShowText', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            document.body.style.overflow = 'hidden';
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <div className="PopUp">
                <div className="all ShowText">
                    <div className="div_topic">
                        <div className="title">{ modalData.title }</div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close color="#f00000" className="icons" />
                        </div>
                    </div>

                    <div className="div_content" dangerouslySetInnerHTML={ { __html: modalData.text.replaceAll('&#34;', '"') } } />
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
        : <React.Fragment></React.Fragment>)
    );
}
