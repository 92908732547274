import { useState, useEffect } from "react";

import './Purchase.css';

import { SetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";
import { GetUserData } from "interface/Users";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetPurchasePage, SetListPurchaseSingle } from "interface/Purchase";
import { RegisterPurchase } from "services/AccessLogin";

export default function Purchase(props){

    const [ showData, setShowData ] = useState(GetDataPage('settings'));
    const [ listPurchase, setListPurchase ] = useState(GetPurchasePage('purchase'));

    let showPriceTotal = 0.00;

    function HandleData(type, index, value, id){
        const newData = [...listPurchase];
        if(parseInt(value) === '0' || parseInt(value) === 0){
            newData.splice(index, 1);      
            SetListPurchaseSingle('remuve', id, newData);
        }else {
            SetListPurchaseSingle('add', id, newData);
            newData[index][type] = parseInt(value);
        }
        setListPurchase(newData); 
    }

    function FinishedPurchase(){
        if(GetUserData('id') == 0){
            SetModalData('Logar', { "type": "conectar" });
            SetModalState('Logar', true);
        }else {
            RegisterPurchase(GetUserData('id'), listPurchase, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });  
        }
    }

    function CallbackSuccess(){
        SetListPag('page', 'dashboard');
        SetListPurchaseSingle('remuveAll');
        SetModalData('ReturnResponse', { "page": "ok", "text": "Compra finalizada com sucesso!" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        SetModalData('ReturnResponse', { "page": "ok", "text": "Erro ao registrar compra!" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="Purchase">
            <div className="container">
                <div className="list_purchase">
                    <div className="title">
                        <div className="">Sacola de compra</div>
                        <div className="qtd">{ listPurchase.length } { listPurchase.length > 1 ? "itens" : "item" }</div>
                    </div>
                    <div className="div_list">
                        {
                            listPurchase.map((elem, index)=>{                                
                                let img = '';
                                let showDataImg = elem.gallery.find(item => item.highlight == true);
                                if(showDataImg){
                                    img = showDataImg.file;
                                }

                                // price total
                                if(elem.discount_price !=''){
                                    let showPrice = parseFloat(elem.discount_price.replace('.', '').replace(',', '.'));
                                    showPriceTotal += showPrice;

                                }else {
                                    let showPrice = parseFloat(elem.price.replace('.', '').replace(',', '.'));
                                    showPriceTotal += showPrice;
                                }

                                return(          
                                    <div className="inf_product" key={ index }>  
                                        <div className="div_img" style={ { backgroundImage: 'url("' + img + '")' } } /> 
                                    
                                        <div className="details_product">
                                            <div className="title_product">{ elem.title }</div>
                                            <div className="subtitle_product">{ elem.subtitle }</div>
                                            <div className="div_inf_purchase">
                                                <div className="div_input">
                                                    <div className="">Tamanho</div>
                                                    <input type="text" value={ elem.size } readOnly />
                                                </div>
                                                <div className="div_input">
                                                    <div className="">
                                                        Valor 
                                                        {
                                                            elem.discount_price !='' ?
                                                                <span className="discount_price"> R$ { elem.price }</span>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    <input type="text" className="price_input" value={ elem.discount_price == "" ? elem.price : elem.discount_price } readOnly />
                                                </div>
                                                <div className="div_input">
                                                    <div className="">Qts</div>
                                                    <input type="text" className="qtd" onChange={ (e)=>{ HandleData('qtd_purchase', index, e.target.value, elem.id) } } value={ elem.qtd_purchase } />
                                                </div>
                                                <div className="div_remuve">
                                                    <div className="btn_remuve" onClick={ ()=>{ HandleData('qtd_purchase', index, 0, elem.id) } }>Remover</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="div_price">
                    <div className="title">Resumo</div>
                    <div className="subtitle">
                        <div className="">
                            Subtotal ({ listPurchase.length } { listPurchase.length > 1 ? "itens" : "item" })
                        </div>
                        <div className="span">
                            R$ { showPriceTotal.toFixed(2).replace('.', ',') }
                        </div>                        
                    </div>
                    <div className="div_show_price">
                        <div className="">Total</div>
                        <div className="div_inf_price">
                            <div className="">R$ { showPriceTotal.toFixed(2).replace('.', ',') }</div>
                            <div className="price_x">em até 12x R$ { (showPriceTotal / 12).toFixed(2).replace('.', ',') }</div>
                        </div>
                    </div>
                    <div className="btn_finished" style={ { background: showData['default_color'] } } onClick={ ()=>{ FinishedPurchase() }}>Finalizar compra</div>
                    <div className="btn_finished" style={ { borderColor: showData['default_color'], color: showData['default_color'] } } onClick={ ()=>{ SetListPag('page', 'index') } }>Continuar comprando</div>
                </div>
            </div>
        </div>
    )
}