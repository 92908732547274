
let PurchasePage = {
    "purchase": [],
};

let NotifyPurchasePage = {
    "purchase": [],
}

export function SetListPurchase(value) {
    PurchasePage = value;
    NotifyPurchasePage["purchase"].forEach(element => { element(value['purchase']); });
}

export function SetListPurchaseSingle(type, id, value) {
    if(type === 'add'){
        const checkedData = PurchasePage['purchase'].find(item => item.id == id);
        if(checkedData){
            const index = PurchasePage['purchase'].findIndex(item => item.id == id);
            PurchasePage['purchase'][index]['qtd_purchase'] = PurchasePage['purchase'][index]['qtd_purchase'] + 1;

        }else {            
            PurchasePage['purchase'].push(value);
            const index = PurchasePage['purchase'].findIndex(item => item.id == id);
            PurchasePage['purchase'][index]['qtd_purchase'] = 1;
        }

    }else if(type === 'remuve'){
        const index = PurchasePage['purchase'].findIndex(item => item.id == id);
        PurchasePage['purchase'].splice(index, 1);
        console.log(PurchasePage['purchase']);

    }

    if(type === 'remuveAll'){
        PurchasePage['purchase'] = [];
        NotifyPurchasePage["purchase"].forEach(element => { element([]); });
    }else {
        NotifyPurchasePage["purchase"].forEach(element => { element(value); });
    }
}

export function GetPurchasePage(key){
    return PurchasePage[key];
}

export function RegisterPurchasePage(key, value){
    if(!NotifyPurchasePage[key]){
        NotifyPurchasePage[key] = [];
    }
    NotifyPurchasePage[key].push(value);
}

export function UnRegisterPurchasePage(key, callback){
    if(NotifyPurchasePage[key].length > 0){
        NotifyPurchasePage[key] = NotifyPurchasePage[key].filter((item) => {
            return item !== callback;
        });
    }
}