import React, { useState, useEffect } from "react";

import './ShowProduct.css';

import { GetDataPage } from "interface/Data";
import { SetListPurchaseSingle } from "interface/Purchase";
import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { Svg_Arrow, Svg_Close, Svg_Purchase } from "services/SvgFile";

import Toast from "components/Toast";

export default function PopUP_ShowProduct(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ showColor, setShowCoolor ] = useState(GetDataPage('settings'));

    const [ indexImg, setIndexImg ] = useState(0);
    const [ status, setStatus ] = useState(false);

    function ClosePopUp(){
        document.body.style.overflow = 'auto';
        
        SetModalData('ShowProduct', {});
        SetModalState('ShowProduct', false);
    }

    function OpenToast(){
        setStatus(true);
        setTimeout(() => {
            setStatus(false);
        }, 2000);
    }

    useEffect(()=>{
        RegisterModalData('ShowProduct', setModaldata);
        RegisterModalObserver('ShowProduct', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            document.body.style.overflow = 'hidden';
            
            setIndexImg(modalData.index);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <div className="PopUp">
                {
                    status ?
                        <Toast text="Produto adicionado com sucesso!" />
                    : null
                }
                <div className="all ShowProduct">
                    <div className="div_topic">
                        <div className="title">{ modalData.title }</div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close color="#f00000" className="icons" />
                        </div>
                    </div>
                    <div className="div_content">
                        <div className="div_gallery">
                            {
                                indexImg == 0 ? null :
                                <div className="left" onClick={ ()=>{ setIndexImg(indexImg - 1) } }>
                                    <Svg_Arrow className="icons" color={ showColor['default_color'] } />
                                </div>
                            }
                            <div className="show_img" style={ { backgroundImage: 'url("' + modalData.data['gallery'][indexImg]['file'] + '")' } } />
                            {
                                indexImg == (modalData.data['gallery'].length - 1) ? null :
                                <div className="right" onClick={ ()=>{ setIndexImg(indexImg + 1) } }>
                                    <Svg_Arrow className="icons" color={ showColor['default_color'] } />
                                </div>
                            }
                        </div>

                        <div className="div_title">
                            <div className="title">{ modalData.data['title'] }</div>
                            <div className="subtitle">{ modalData.data['subtitle'] }</div>
                        </div>

                        <div className="div_inf_product">
                            <div className="type_data" style={ { background: showColor['default_color'] } }>Características principais</div>
                            <div className="div_show_data" style={ { borderColor: showColor['default_color'] } }>
                                <div className=""><b>Lateral: </b>{ modalData.data['side'] }</div>
                                <div className=""><b>Descrição do produto: </b>{ modalData.data['text'] }</div>
                            </div>
                        </div>

                        <div className="div_inf_product">
                            <div className="type_data" style={ { background: showColor['default_color'] } }>Características gerais</div>
                            <div className="div_show_data div_show_product_" style={ { borderColor: showColor['default_color'] } }>
                                <div className="">
                                    <div className=""><b>Marcas: </b>{ modalData.data['brands'] }</div>
                                    <div className=""><b>Linha: </b>{ modalData.data['line'] }</div>
                                </div>
                                <div className="">
                                    <div className=""><b>Modelo: </b>{ modalData.data['model'] }</div>
                                    <div className=""><b>Tamanho: </b>{ modalData.data['size'] }</div>
                                </div>
                            </div>
                        </div>

                        <div className="div_inf_product">
                            <div className="type_data" style={ { background: showColor['default_color'] } }>Especificações</div>
                            <div className="div_show_data" style={ { borderColor: showColor['default_color'] } }>
                                <div className="div_show_product_">
                                    <div className=""><b>Índice de carga: </b>{ modalData.data['load_index'] }</div>
                                    <div className=""><b>Índice de velocidade: </b>{ modalData.data['speed_index'] }</div>
                                </div>
                                <div className="div_show_product_">
                                    <div className=""><b>Faixa de carga: </b>{ modalData.data['load_range'] }</div>
                                    <div className=""><b>Tipo de construção: </b>{ modalData.data['type_construction'] }</div>
                                </div>
                                <div className="div_show_product_">
                                    <div className=""><b>Relação de aspecto do pneu: </b>{ modalData.data['aspect_ratio'] }</div>
                                </div>
                            </div>
                        </div>

                        <div className="div_inf_product">
                            <div className="type_data" style={ { background: showColor['default_color'] } }>Uso</div>
                            <div className="div_show_data" style={ { borderColor: showColor['default_color'] } }>
                                <div className=""><b>Tipo de serviço: </b>{ modalData.data['type_service'] }</div>
                                <div className=""><b>Tipo de veículo: </b>{ modalData.data['vehicle_type'] }</div>
                                <div className=""><b>Tipo de terreno: </b>{ modalData.data['type_terrain'] }</div>
                            </div>
                        </div>

                        <div className="div_inf_product">
                            <div className="type_data" style={ { background: showColor['default_color'] } }>Tecnologia</div>
                            <div className="div_show_data" style={ { borderColor: showColor['default_color'] } }>
                                { modalData.data['technology'] }
                            </div>
                        </div>

                        <div className="div_inf_product">
                            <div className="type_data" style={ { background: showColor['default_color'] } }>Dimensões</div>
                            <div className="div_show_data">
                                <div className="">
                                    <div className=""><b>Largura de secção: </b>{ modalData.data['section_width'] }</div>
                                    <div className=""><b>Diâmetro de roda: </b>{ modalData.data['wheel_diameter'] }</div>
                                </div>
                            </div>
                        </div>

                        <div className="div_inf_product div_price_product">
                            <div className="price" style={ { color: showColor['default_color'] } }>
                                {
                                    modalData.data['discount_price'] == "" ?
                                    <div className="">R$ { modalData.data['price'] }</div> :
                                    <>
                                        <div className="show_price">
                                            De: R$ <span className="discount_price">{ modalData.data['discount_price'] }</span>
                                        </div>
                                        <div className="">
                                            Por: R$ { modalData.data['discount_price'] }
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="div_btn">                                
                                <div className="btn" onClick={ ()=>{ SetListPurchaseSingle('add', modalData.data['id'], modalData.data); OpenToast(); } } style={ { backgroundColor: showColor['default_color'] } }>
                                    <Svg_Purchase className="icons" color="#ffffff" />
                                    <div className="title_btn">Adicionar</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
        : <React.Fragment></React.Fragment>)
    );
}
