import React, { useState, useEffect } from "react";

import './Logar.css';

import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { Svg_Close } from "services/SvgFile";
import { Access, Register } from "services/AccessLogin";
import { cepMask } from "services/Mask";
import { GetDataPage } from "interface/Data";
import { phoneMask } from "fixedData";

export default function PopUP_Logar(props){

    const [ showData, setShowData ] = useState(GetDataPage('settings'));

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ typeData, setTypeData ] = useState('');

    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password_1, setPassword_1 ] = useState('');
    const [ password_2, setPassword_2 ] = useState('');
    const [ complement, setComplement ] = useState('');
    const [ phone, setPhone ] = useState('');
    const [ cep, setCep ] = useState('');
    const [ street, setStreet ] = useState('');
    const [ number, setNumber ] = useState('');
    const [ state, setState ] = useState('');
    const [ city, setCity ] = useState('');
    const [ neighborhood, setNeighborhood ] = useState('');
    const [ msgError, setMsgError ] = useState('');

    function ClosePopUp(){
        document.body.style.overflow = 'auto';
        
        props.setLoading(false);

        setName('');
        setEmail('');
        setPassword_1('');
        setPassword_2('');
        setComplement('');
        setPhone('');
        setCep('');
        setStreet('');
        setNumber('');
        setState('');
        setCity('');
        setNeighborhood('');

        SetModalData('Logar', {});
        SetModalState('Logar', false);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        if(typeData === "logar"){
            Access('logar', email, password_1, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });

        }else if(typeData === "create_user"){
            Register(0, name, email, password_1, complement, phone, number, neighborhood, cep, street, state, city, ()=>{ CallbackSuccess() }, ()=>{ CallbackErrorRegister() });
        }
    }

    function CallbackSuccess(){
        ClosePopUp();
        props.setLoading(false);
        props.CallbackSuccess();
    }

    function CallbackError(){
        props.setLoading(false);
        setMsgError('E-mail ou senha incorreto!');
    }

    function CallbackErrorRegister(){
        props.setLoading(false);
        props.CallbackError('Cadastrar', 'Erro ao registrar as informações!');
    }

    useEffect(()=>{
        RegisterModalData('Logar', setModaldata);
        RegisterModalObserver('Logar', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            document.body.style.overflow = 'hidden';
            
            setTypeData(modalData.type);
            setName('');
            setEmail('');
            setPassword_1('');
            setPassword_2('');
            setComplement('');
            setPhone('');
            setCep('');
            setStreet('');
            setNumber('');
            setState('');
            setCity('');
            setNeighborhood('');
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <form onSubmit={ SaveData } className="PopUp PopUpCenter">
                <div className="all Logar">
                    <div className="div_topic">
                        <div className="title">
                            { 
                                typeData == "logar" ? "Acesse sua conta" : 
                                typeData === "conectar" ? "Acessar a conta ou registra-se" :  "Cadastre-se" 
                            }
                        </div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close color="#f00000" className="icons" />
                        </div>
                    </div>

                    <div className="div_content">
                        {
                            typeData === "conectar" ?
                                <div className="div_purchase">
                                    <div className="btn" style={ { background: showData['default_color'] } } onClick={ ()=>{ setTypeData('logar') } }>
                                        Acesse sua conta
                                    </div>
                                    <div className="btn" style={ { borderColor:  showData['default_color'], color: showData['default_color'] } } onClick={ ()=>{ setTypeData('create_user') } }>
                                        Registrar-se
                                    </div>
                                </div>
                            :
                            null
                        }
                        {
                            typeData === "logar" ?
                            <>
                                <div className="div_input">
                                    <div className="two_input">
                                        <input type="email" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } required maxLength={ 30 } />
                                        <div className="name_input">E-mail*</div>
                                    </div>
                                    <div className="two_input">
                                        <input type="password" onChange={ (e)=>{ setPassword_1(e.target.value) } } value={ password_1 } required maxLength={ 60 } />
                                        <div className="name_input">Senha*</div>
                                    </div>
                                </div>

                                {
                                    msgError !='' ?
                                        <div className="error">{ msgError }</div>
                                    : null
                                }

                                <button type="submit" className="btn" style={ { background: showData['default_color'] } }>
                                    Salvar
                                </button> 
                            </>
                            :
                                typeData === "create_user" ?
                                <>
                                    <div className="div_input">
                                        <div className="two_input">
                                            <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } required maxLength={ 30 } />
                                            <div className="name_input">Nome*</div>
                                        </div>
                                        <div className="two_input">
                                            <input type="email" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } required maxLength={ 60 } />
                                            <div className="name_input">E-mail*</div>
                                        </div>
                                    </div>

                                    <div className="div_input">
                                        <div className="two_input">
                                            <input type="text" onChange={ (e)=>{ setCity(e.target.value) } } value={ city } maxLength={ 140 } />
                                            <div className="name_input">Cidade</div>
                                        </div>
                                        <div className="two_input uf">
                                            <input type="text" onChange={ (e)=>{ setState(e.target.value) } } value={ state } maxLength={ 3 } />
                                            <div className="name_input">UF</div>
                                        </div>
                                    </div>

                                    <div className="div_input">
                                        <div className="two_input cep">
                                            <input type="text" onChange={ (e)=>{ setCep(e.target.value) } } value={ cepMask(cep) } />
                                            <div className="name_input">CEP</div>
                                        </div>
                                        <div className="two_input">
                                            <input type="text" onChange={ (e)=>{ setNeighborhood(e.target.value) } } value={ neighborhood } maxLength={ 140 } />
                                            <div className="name_input">Bairro</div>
                                        </div>
                                    </div>

                                    <div className="div_input">
                                        <div className="two_input">
                                            <input type="text" onChange={ (e)=>{ setStreet(e.target.value) } } value={ street } maxLength={ 140 } />
                                            <div className="name_input">Rua</div>
                                        </div>
                                        <div className="two_input cep">
                                            <input type="text" onChange={ (e)=>{ setNumber(e.target.value) } } value={ number } maxLength={ 6 } />
                                            <div className="name_input">Número</div>
                                        </div>
                                    </div>

                                    <div className="div_input">
                                        <div className="two_input">
                                            <input type="text" onChange={ (e)=>{ setComplement(e.target.value) } } value={ complement } maxLength={ 140 } />
                                            <div className="name_input">Complemento</div>
                                        </div>
                                        <div className="two_input contact">
                                            <input type="text" onChange={ (e)=>{ setPhone(e.target.value) } } value={ phoneMask(phone) } maxLength={ 30 } />
                                            <div className="name_input">Contato</div>
                                        </div>
                                    </div>

                                    <div className="div_input">
                                        <div className="two_input">
                                            <input type="password" onChange={ (e)=>{ setPassword_1(e.target.value) } } value={ password_1 } required />
                                            <div className="name_input">Senha*</div>
                                        </div>
                                        <div className="two_input">
                                            <input type="password" onChange={ (e)=>{ setPassword_2(e.target.value) } } value={ password_2 } required />
                                            <div className="name_input">Confirmação da senha*</div>
                                        </div>
                                    </div>

                                    {
                                        password_1 === '' || password_2 === '' ? null :
                                            password_1 === password_2 ?
                                            <button type="submit" className="btn" style={ { background: showData['default_color'] } }>
                                                Salvar
                                            </button>
                                            : 
                                            <div className="error">As senhas devem ser iguais!</div>
                                    }
                                </>
                            : null
                        }
                    </div>
                </div>
            </form>
        : <React.Fragment></React.Fragment>)
    );
}
