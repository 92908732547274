import React, { useState, useEffect } from "react";

import './ReturnResponse.css';

import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { Svg_ChekedReturn, Svg_Erro } from "services/SvgFile";

export default function PopUP_ReturnResponse(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    function ClosePopUp(){
        document.body.style.overflow = 'auto';

        SetModalData('ReturnResponse', {});
        SetModalState('ReturnResponse', false);
    }

    function TypeData(){
        switch (modalData.page) {
            case 'error':
                return(
                    <>
                        <Svg_Erro color="#E1005A" className="icons" />
                        <div className="title_return">
                            { modalData.text == "" ? "Erro ao registrar as informações, tente novamente!" : modalData.text }
                        </div>
                    </>
                )

            default:
                return(
                    <>
                        <Svg_ChekedReturn color="#76c57e" className="icons" />
                        <div className="title_return">
                            Informações registradas com sucesso.
                        </div>
                    </>
                )
        }
    }    

    useEffect(()=>{
        RegisterModalData('ReturnResponse', setModaldata);
        RegisterModalObserver('ReturnResponse', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            document.body.style.overflow = 'hidden';
        }
    }, [showPopUp]);

    return(
        (showPopUp ?
            <div className="PopUp PopUpCenter">
                <div className="all ReturnResponse">
                    <div className="div_content">
                        {
                            TypeData()
                        }
                        <div className={ modalData.page === "error" ? "btn close_error" : "btn close_ok" } onClick={ ()=>{ ClosePopUp() } }>
                            Fechar
                        </div> 
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
        : <React.Fragment></React.Fragment>)
    )
};
