import { useState, useEffect } from "react";

import Cookies from 'universal-cookie';

import './User.css';

import { GetUserData, RegisterUserData, SetUserData } from "interface/Users";
import { GetDataPage } from "interface/Data";

import { cepMask } from "services/Mask";
import { Register } from "services/AccessLogin";
import { SetListPag } from "interface/Page";
import { cookiesRegister, typeCookieEmail, typeCookiePassw } from "fixedData";
import { SetModalData, SetModalState } from "interface/PopUp";
import PopUP_DetailsPurchase from "components/PopUp/DetailsPurchase";

export default function UserSite(props){
    const cookies = new Cookies();

    const [ showData, setShowData ] = useState(GetDataPage('settings'));
    const [ page, setPage ] = useState('access');

    const [ id, setId ] = useState(GetUserData('id'));
    const [ name, setName ] = useState(GetUserData('name'));
    const [ email, setEmail ] = useState(GetUserData('email'));
    const [ password_1, setPassword_1 ] = useState('');
    const [ complement, setComplement ] = useState(GetUserData('complement'));
    const [ phone, setPhone ] = useState(GetUserData('phone'));
    const [ cep, setCep ] = useState(GetUserData('cep'));
    const [ street, setStreet ] = useState(GetUserData('street'));
    const [ number, setNumber ] = useState(GetUserData('number'));
    const [ state, setState ] = useState(GetUserData('state'));
    const [ city, setCity ] = useState(GetUserData('city'));
    const [ neighborhood, setNeighborhood ] = useState(GetUserData('neighborhood'));
    const [ purchase, setPurchase ] = useState(GetUserData('purchase'));

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Register(GetUserData('id'), name, email, password_1, complement, phone, number, neighborhood, cep, street, state, city, ()=>{ props.CallbackSuccess() }, ()=>{ props.CallbackError() });        
    }

    function Logout(){
        SetUserData('id', 0);
        SetUserData('file', '');
        SetUserData('name', '');
        SetUserData('email', '');
        SetUserData('cep', '');
        SetUserData('street', '');
        SetUserData('state', '');
        SetUserData('city', '');
        SetUserData('purchase', []);
        SetUserData('complement', '');
        SetUserData('phone', '');
        SetUserData('number', '');
        SetUserData('neighborhood', '');
        SetListPag('page', 'index');
        cookies.remove(typeCookieEmail, "", '/', cookiesRegister);
        cookies.remove(typeCookiePassw, "", '/', cookiesRegister);
    }

    function OpenDetails(elem){
        SetModalData('DetailsPurchase', elem);
        SetModalState('DetailsPurchase', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setId);
        RegisterUserData('name', setName);
        RegisterUserData('email', setEmail);
        RegisterUserData('complement', setComplement);
        RegisterUserData('phone', setPhone);
        RegisterUserData('cep', setCep);
        RegisterUserData('street', setStreet);
        RegisterUserData('number', setNumber);
        RegisterUserData('state', setState);
        RegisterUserData('city', setCity);
        RegisterUserData('neighborhood', setNeighborhood);
        RegisterUserData('purchase', setPurchase);
    }, []);

    return(
        <div className="User">
            <form onSubmit={ SaveData } className="container">
                <div className="show_data_user">
                    <div className="div_show_data_user">
                        <div className="purchase" onClick={ ()=>{ setPage('purchase'); } } style={ { background: page === "purchase" ? showData['default_color'] : "inherit", color: page === "purchase" ? "#ffffff" : showData['default_color'] } }>Compras realizadas</div>
                        <div className="purchase" onClick={ ()=>{ setPage('access'); } } style={ { background: page === "access" ? showData['default_color'] : "inherit", color: page === "access" ? "#ffffff" : showData['default_color'] } }>Dados de acesso</div>
                        <div className="logout" onClick={ ()=>{ Logout() } }>Sair</div>
                    </div>
                    {
                        page === "purchase" ?
                        <>
                            {
                                purchase.length > 0 ?
                                purchase.map((elem, index)=>{
                                    console.log(elem);
                                    let status = "Pendente";
                                    if(elem.status == 1){
                                        status = "Realizado";
                                    }else if(elem.status == 2){
                                        status = "Cancelado";
                                    }

                                    return(
                                        <div className="div_purchase" key={ index }>
                                            <div className="div_date">
                                                <div className=""><b>Data da compra:</b> { elem.register_date }</div>
                                                <div className=""><b>Quatidade de item:</b> { elem.qtd_item }</div>
                                                <div className=""><b>Valor total:</b> R$ { elem.price }</div>
                                                <div className=""><b>Pagamento:</b> { elem.status }</div>
                                            </div>
                                            <div className="div_btn" onClick={ ()=>{ OpenDetails(elem) } }>
                                                Detalhes 
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div className="no_data">Nenhuma compra realizada até o momento</div>
                            }
                        </>
                        :
                        <>
                            <div className="div_input">
                                <div className="two_input space_div">
                                    <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } required maxLength={ 30 } />
                                    <div className="name_input">Nome*</div>
                                </div>
                                <div className="two_input space_div">
                                    <input type="email" onChange={ (e)=>{ setEmail(e.target.value.replaceAll(' ', '')) } } value={ email } required maxLength={ 60 } />
                                    <div className="name_input">E-mail*</div>
                                </div>
                                <div className="two_input password">                        
                                    <input type="password" className="" onChange={ (e)=>{ setPassword_1(e.target.value.replaceAll(' ', '')) } } value={ password_1 } maxLength={ 12 } />
                                    <div className="name_input">Senha*</div>
                                </div>
                            </div>

                            <div className="div_input">
                                <div className="two_input space_div">
                                    <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } required maxLength={ 140 } />
                                    <div className="name_input">Complemento</div>
                                </div>
                                <div className="two_input contact">
                                    <input type="text" onChange={ (e)=>{ setPhone(e.target.value.replaceAll(' ', '')) } } value={ email } maxLength={ 30 } />
                                    <div className="name_input">Contato</div>
                                </div>
                            </div>

                            <div className="div_input">
                                <div className="two_input space_div">
                                    <input type="text" onChange={ (e)=>{ setCity(e.target.value) } } value={ city } maxLength={ 80 } />
                                    <div className="name_input">Cidade</div>
                                </div>
                                <div className="two_input uf">
                                    <input type="text" onChange={ (e)=>{ setState(e.target.value) } } value={ state } maxLength={ 3 } />
                                    <div className="name_input">UF</div>
                                </div>

                                <div className="two_input cep">
                                    <input type="text" onChange={ (e)=>{ setCep(e.target.value) } } value={ cepMask(cep) } />
                                    <div className="name_input">CEP</div>
                                </div>
                                <div className="two_input space_div">
                                    <input type="text" onChange={ (e)=>{ setNeighborhood(e.target.value) } } value={ neighborhood } maxLength={ 140 } />
                                    <div className="name_input">Bairro</div>
                                </div>
                                <div className="two_input space_div">
                                    <input type="text" onChange={ (e)=>{ setStreet(e.target.value) } } value={ street } maxLength={ 140 } />
                                    <div className="name_input">Rua</div>
                                </div>
                                <div className="two_input cep">
                                    <input type="text" onChange={ (e)=>{ setNumber(e.target.value) } } value={ number } maxLength={ 6 } />
                                    <div className="name_input">Número</div>
                                </div>
                            </div>

                            <button type="submit" className="btn" style={ { background: showData['default_color'] } }>
                                Salvar aleração
                            </button>
                        </>
                    }
                </div>
            </form>
            <PopUP_DetailsPurchase />
        </div>
    )
}