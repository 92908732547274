import React, { useState, useEffect } from "react";

import './ShowFile.css';

import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { Svg_Close } from "services/SvgFile";

export default function PopUP_ShowFile(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    function ClosePopUp(){
        document.body.style.overflow = 'auto';
        
        SetModalData('ShowFile', {});
        SetModalState('ShowFile', false);
    }

    useEffect(()=>{
        RegisterModalData('ShowFile', setModaldata);
        RegisterModalObserver('ShowFile', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            document.body.style.overflow = 'hidden';
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <div className="PopUp">
                <div className="all ShowFile">
                    <div className="div_topic">
                        <div className="title" />
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close color="#f00000" className="icons" />
                        </div>
                    </div>

                    <div className="div_content">
                        <img alt="img" src={ modalData.file } className="show_img" />
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
        : <React.Fragment></React.Fragment>)
    );
}
