import { useState, useEffect } from "react";

import './SlideShow.css';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import { GetDataPage } from "interface/Data";

export default function Comp_SlideShow(props){

    const [ showData, setShowData ] = useState(GetDataPage('settings'));
    const [ showSlide, setShowSlide ] = useState(GetDataPage('slide_show'));

    function AltColorIcons(){
        let icon_first = document.querySelector('.nav:first-of-type');
        if (icon_first) {
            icon_first.style.background = showData['default_color'];
        }

        let icon_last = document.querySelector('.nav:last-of-type');
        if (icon_last) {
            icon_last.style.background = showData['default_color'];
        }
    }

    useEffect(()=>{
        AltColorIcons();
    }, []);

    return(
        showSlide.length > 0 ?
        <div className="SlideShow">
            <Slide>
                {
                    showSlide.map((elem, index)=>{
                        return(
                            <div className="each-slide-effect" key={ index }>
                                <div className="show_img_slide_show" style={ { backgroundImage: 'url("' + elem.file + '")' } } />
                            </div>
                        )
                    })
                }
            </Slide>
        </div>
        : null
    )
}