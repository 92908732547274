import { useState } from "react";

import './Toast.css';

export default function Toast(props){



    return(
        <div className="Toast">
            <div className="">
                { props.text }
            </div>
        </div>
    )
}