import { useState, useEffect } from "react";

import Cookies from 'universal-cookie';

import PopUP_ShowFile from "components/PopUp/ShowFile";
import PopUP_ShowText from "components/PopUp/ShowText";
import PopUP_Confirmation from "components/PopUp/Confirmation";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

import { GetDataPage } from "interface/Data";
import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";

import Comp_Btn from "components/Btn";
import Comp_Footer from "components/Footer";
import Comp_Header from "components/Header";
import Comp_SlideShow from "components/SlideShow";

import PopUP_Logar from "components/PopUp/Logar";
import PopUP_ShowProduct from "components/PopUp/ShowProduct";

import UserSite from "content/User";
import Purchase from "content/Purchase";
import Content_Faq from "content/Faq";
import Content_Banner from "content/Banner";
import Content_Brands from "content/Brands";
import Content_Product from "content/Product";
import Content_Category from "content/Category";

import { typeCookieEmail, typeCookiePassw } from "fixedData";

import { Access } from "services/AccessLogin";

export default function App(props) {

    const cookies  = new Cookies();

    const [ loading, setLoading ] = useState(false); 
    const [ currentPage, setCurrentPage ] = useState(GetListPag('page'));
    const [ showData, setShowData ] = useState(GetDataPage('site'));

    function OpenFile(file){
        if(file !=''){
            SetModalData('ShowFile', { "file": file });
            SetModalState('ShowFile', true);
        }
    }

    function CallbackSuccess(value){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": value });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(name, value){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "error", "name": name, "text": value });
        SetModalState('ReturnResponse', true);
    }

    function ShowDataPage(type, data){
        switch (type) {
            case "banner":
                return <Content_Banner data={ data } />;

            case "category":
                return <Content_Category data={ data.contents } />;

            case "product": 
                return <Content_Product data={ data.contents } OpenFile={ OpenFile } />;

            case "brands":
                return <Content_Brands data={ data.contents } />;

            case "faq":
                return <Content_Faq data={ data.contents } />;
        }
    }

    useEffect(()=>{
        RegisterListPag('page', setCurrentPage);

        if(cookies.get(typeCookiePassw) || cookies.get(typeCookieEmail)){
            let return_pass  = cookies.get(typeCookiePassw);
            let return_email = cookies.get(typeCookieEmail);
            Access('return', return_email, return_pass, ()=>{ }, ()=>{ });
        }
    }, []);

    useEffect(()=>{
        ShowDataPage()
    }, [currentPage]);

    return(
        <>
            <div className={ loading ? "return_data_save div_loading" : "return_data_save" }>
                <div className="ld-ripple">
                    <div />
                    <div />
                </div>
            </div>

            <Comp_Header setLoading={ setLoading } />
            
            {
                currentPage === "dashboard" ?
                <UserSite OpenFile={ OpenFile } setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />
                :
                    currentPage === "purchase" ?
                    <Purchase OpenFile={ OpenFile } setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />
                    :
                    <>
                        <Comp_SlideShow />

                        <Comp_Btn />
                        {
                            showData.map((elem, index)=>{
                                return (
                                    <div className={ elem.origin === "banner" ? "show_data_page padding_0" : "show_data_page" } key={ index }>
                                        { ShowDataPage(elem.origin, elem) }
                                    </div>
                                )                    
                            })
                        }
                    </>
            }

            <Comp_Footer />
            
            <PopUP_ShowProduct setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />
            <PopUP_Logar setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />
            
            <PopUP_ShowFile />
            <PopUP_ShowText />
            <PopUP_Confirmation setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />
            <PopUP_ReturnResponse />
        </>
    );
}