import { useState, useEffect } from "react";

import './Footer.css';

import { GetDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";

import { Svg_Email, Svg_Facebook, Svg_Instagram, Svg_Linkedin, Svg_Tiktok, Svg_Whatsapp, Svg_Youtube } from "services/SvgFile";

import { phoneMask } from "fixedData";

export default function Comp_Footer(props){

    const [ showData, setShowData ] = useState(GetDataPage('settings'));

    function OpenData(title, text){
        SetModalData('ShowText', { "title": title, "text": text });
        SetModalState('ShowText', true);
    }
    
    return(
        <footer id="footer" style={ { color: showData['footer_text_color'] } }>
            <div className="container div_show_data_company">
                <div className="div_opt">
                    <div className="title">Sobre a Empresa</div>
                    <div className="show_data">
                        <div className="" onClick={ ()=>{ OpenData('Quem somos', showData['about']) } }>Quem somos</div>
                        <div className="">Seja um Franqueado</div>
                        <div className="">Onde estamos</div>
                        <div className="div_logo">
                            <img alt="logo" src={ showData['logo'] } className="logo" />
                        </div>
                    </div>
                </div>
                <div className="div_opt">
                    <div className="title">Precisa de Ajuda?</div>
                    <div className="show_data">
                        <div className="">Perguntas Frequentes</div>
                        <div className="" onClick={ ()=>{ OpenData('Política de Privacidade', showData['privacy_policy']) } }>Política de Privacidade</div>
                        <div className="" onClick={ ()=>{ OpenData('Regulamentos', showData['regulations']) } }>Regulamentos</div>
                        <div className="" onClick={ ()=>{ OpenData('Termo de Consentimento de Acordo LGPD', showData['consent_form']) } }>Termo de Consentimento de Acordo LGPD</div>
                        <div className="" onClick={ ()=>{ OpenData('Segurança', showData['security']) } }>Segurança</div>
                        <div className="" onClick={ ()=>{ OpenData('Termos e Condições', showData['terms_conditions']) } }>Termos e Condições</div>
                        <div className="" onClick={ ()=>{ OpenData('Vantagens', showData['benefits']) } }>Vantagens</div>
                        <div className="">Trabalhe Conosco</div>
                    </div>
                </div>
                <div className="div_opt">
                    <div className="title">Central de Atendimento</div>
                    <div className="show_data">
                        <div className="">Atendimento Exclusivo:</div>
                        <div className="div_icon">
                            <a href={ "https://wa.me/" + showData['whatsapp'] + "?text=Olá%2C%20gostaria%20de%20mais%20informações." } target="_blank">
                                <div className="">
                                        <Svg_Whatsapp className="icons" color={ showData['footer_text_color'] } />
                                </div>
                                <div className="">{ phoneMask(showData['whatsapp']) }</div>
                            </a>
                        </div>
                        <div className="">E-mail:</div>
                        <div className="div_icon">
                            <div className="">
                                <Svg_Email className="icons" color={ showData['footer_text_color'] } />
                            </div>
                            <div className="">{ showData['email'] }</div>
                        </div>
                        <div className="">Segunda à Sexta das 09h às 18h</div>

                        <div className="title">Siga-nos</div>
                        <div className="list_icons">
                            {
                                showData['facebook'] !='' ?
                                    <div className="" style={ { background: showData['footer_text_color'] } }>
                                        <a href={ showData['facebook'] } target="_blank">
                                            <Svg_Facebook className="icons" color="#FFFFFF" />
                                        </a>
                                    </div>
                                : null
                            }
                            
                            {
                                showData['instagram'] !='' ?
                                    <div className="" style={ { background: showData['footer_text_color'] } }>
                                        <a href={ showData['instagram'] } target="_blank">
                                            <Svg_Instagram className="icons" color="#FFFFFF" />
                                        </a>
                                    </div>
                                : null
                            }
                            
                            {
                                showData['youtube'] !='' ?
                                    <div className="" style={ { background: showData['footer_text_color'] } }>
                                        <a href={ showData['youtube'] } target="_blank">
                                            <Svg_Youtube className="icons" color="#FFFFFF" />
                                        </a>
                                    </div>
                                : null
                            }
                            
                            {
                                showData['tiktok'] !='' ?
                                    <div className="" style={ { background: showData['footer_text_color'] } }>
                                        <a href={ showData['tiktok'] } target="_blank">
                                            <Svg_Tiktok className="icons" color="#FFFFFF" />
                                        </a>
                                    </div>
                                : null
                            }
                            
                            {
                                showData['linkedin'] !='' ?
                                    <div className="" style={ { background: showData['footer_text_color'] } }>
                                        <a href={ showData['linkedin'] } target="_blank">
                                            <Svg_Linkedin className="icons" color="#FFFFFF" />
                                        </a>
                                    </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="div_inf font_text">
                <div className="" onClick={ ()=>{ OpenData('Política de Privacidade', showData['privacy_policy']) } }>Política de Privacidade</div>
                <div className="show_bar">|</div>
                <div className="" onClick={ ()=>{ OpenData('Termos & Condições', showData['terms_conditions']) } }>Termos & Condições</div>
                <div className="show_bar">|</div>
                <div className="" onClick={ ()=>{ OpenData('Política de Cancelamento', showData['cancellation_policy']) } }>Política de Cancelamento</div>
                <div className="show_bar">|</div>
                <div className="" onClick={ ()=>{ OpenData('Código de Ética e Conduta', showData['code_ethics']) } }>Código de Ética e Conduta</div>
                <div className="show_bar">|</div>
                <div className="" onClick={ ()=>{ OpenData('Canal Confidencial', showData['confidential_channel']) } }>Canal Confidencial</div>
            </div>
            <div className="div_right_reserved font_text">
                <div className="">Informações para imprensa: { showData['email'] }</div>
                <div className="">© 2024 { showData['company_name'] } - Todos os direitos reservados</div>                
            </div>
        </footer>
    )
}