
// export const cookiesRegister = '';
// export const urlPhp = window.linkClient + 'dashboard/public/site/';

export const urlPhp = window.linkClient + 'site/';
export const cookiesRegister = '.fastevolution.com.br';

// Cookies name
export const typeCookiePassw = 'clicksite_project_passw';
export const typeCookieEmail = 'clicksite_project_email';
export const typeCookiePage = 'clicksite_project_page';

// export const typeCookiePassw = window.nameProject.split('/')[0] + '_project_passw';
// export const typeCookieEmail = window.nameProject.split('/')[0] + '_project_email';
// export const typeCookiePage = window.nameProject.split('/')[0] + '_project_page';

// current date
const day = new Date();

// add 3 day
const add3Days = new Date();
add3Days.setDate(day.getDate() + 3);
export const cookieExpirationTime = add3Days;

export function phoneMask(phone){
    return phone.replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, `$1-$2`)
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1')
}