import React, { useState, useEffect } from "react";

import './Confirmation.css';

import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Svg_Close } from "services/SvgFile";

export default function PopUP_Confirmation(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    function ClosePopUp(){
        document.body.style.overflow = 'auto';
        
        SetModalData('Confirmation', {});
        SetModalState('Confirmation', false);
    }

    useEffect(()=>{
        RegisterModalData('Confirmation', setModaldata);
        RegisterModalObserver('Confirmation', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            document.body.style.overflow = 'hidden';
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <div className="PopUp PopUpCenter">
                <div className="all Confirmation">
                    <div className="div_topic">
                        <div className="title">{ modalData.name }</div>
                    </div>

                    <div className="div_content">
                        <div className="opt">
                            { modalData.text }
                        </div>
                    </div>

                    <div className="div_content">
                        <div className="btn yes">Sim</div>
                        <div className="btn not" onClick={ ()=>{ ClosePopUp(); } }>Não</div>
                    </div>
                </div>
            </div>
        : <React.Fragment></React.Fragment>)
    );
}
