import { useState, useEffect } from "react";

import './Header.css';

import { SetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";
import { GetPurchasePage, RegisterPurchasePage } from "interface/Purchase";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData } from "interface/Users";

import { Svg_Menu, Svg_Purchase, Svg_Search, Svg_User } from "services/SvgFile";

export default function Comp_Header(props){

    const [ showPurchase, setShowPurchase ] = useState(GetPurchasePage('purchase'));

    const [ showData, setShowData ] = useState(GetDataPage('settings'));
    const [ userFile, setUserFile ] = useState(GetUserData('file'));
    const [ userName, setUserName ] = useState(GetUserData('name'));

    const [ status, setStatus ] = useState(false);
    const [ statusMenu, setStatusMenu ] = useState(false);

    function OpenLogin(type, id){
        SetModalData('Logar', { "type": type });
        SetModalState('Logar', true);
    }

    function ShowPurchase(type){
        let qtdPurchase = 0;
        GetPurchasePage('purchase').map((elem, index)=>{
            qtdPurchase += elem.qtd_purchase;
        });

        let img = '';
        let title = '';
        let subtitle = '';
        let price = '';
        let discount_price = '';
        let priceTotal = 0.00;
        let qtd = 0;
        let showDataEnd = GetPurchasePage('purchase')[GetPurchasePage('purchase').length - 1];
        if(showDataEnd){
            qtd = GetPurchasePage('purchase').length;
            img = showDataEnd.gallery.find(item => item.highlight == true)['file'];
            title = showDataEnd.title;
            subtitle = showDataEnd.subtitle;
            
            if(showDataEnd.price !=''){
                let altInf = parseFloat(showDataEnd.price.replace('.', '').replace(',', '.'));
                price = altInf.toFixed(2).replace('.', ',');
            }

            if(showDataEnd.discount_price !=''){
                let altInf = parseFloat(showDataEnd.discount_price.replace('.', '').replace(',', '.'));
                price = altInf.toFixed(2).replace('.', ',');
            }

            GetPurchasePage('purchase').map((elem, index)=>{
                if(elem.discount_price !=''){
                    let showPrice = parseFloat(elem.discount_price.replace('.', '').replace(',', '.'));
                    priceTotal += showPrice;

                }else {
                    let showPrice = parseFloat(elem.price.replace('.', '').replace(',', '.'));
                    priceTotal += showPrice;
                }
            })
        }
        priceTotal = priceTotal.toFixed(2).replace('.', ',');

        return(
            <div className="div_purchase">
                {
                    type == 1 ? null :
                    <>
                        <div className="qtd">{ qtdPurchase }</div>
                        <div className="" onClick={ ()=>{ setStatus(!status); } }>
                            <Svg_Purchase className="icons" color={ showData['default_color'] } />
                        </div>
                    </>
                }
                {
                    status || type == 1 ?
                    <div className="show_purchase" style={ { borderColor: showData['default_color'] } }>
                        {
                            showDataEnd ?
                            <>
                                <div className="div_inf_product">
                                    <div className="img_product" style={ { backgroundImage: 'url("' + img + '")' } } />

                                    <div className="preview_inf">

                                        <div className="inf_product">
                                            <div className="">
                                                <div className="title_product">{ title }</div>
                                                <div className="subtitle_product">{ subtitle }</div>
                                            </div>
                                            <div className="div_price">
                                                {
                                                    discount_price != "" ?
                                                    <>                                                        
                                                        <div className="">
                                                            de 
                                                            <span className="discount_price">R$ { price }</span>
                                                        </div>
                                                        <div className="price">R$ { discount_price }</div>
                                                    </>
                                                    :
                                                    <div className="price">R$ { price }</div>
                                                }
                                            </div>
                                        </div>

                                        <div className="inf_product" style={ { color: showData['default_color'], borderTop: '1px solid ' + showData['default_color'], paddingTop: "8px" } }>
                                            <div className="price">Total de itens:</div>
                                            <div className="price">{ qtd }</div>
                                        </div>

                                        <div className="inf_product" style={ { color: showData['default_color'], borderTop: '1px solid ' + showData['default_color'], paddingTop: "8px" } }>
                                            <div className="price">Subtotal:</div>
                                            <div className="price">R$ { priceTotal }</div>
                                        </div>                                        
                                    </div>
                                </div>

                                <div className="div_btn" style={ { background: showData['default_color'] } } onClick={ ()=>{ SetListPag('page', 'purchase'); setStatus(false); setStatusMenu(false) } }>
                                    <Svg_Purchase className="icons" color="#ffffff" />
                                    <div className="">Ir para a sacola</div>
                                </div>
                            </>
                            :
                            <div className="no_data">
                                Nenhum produto adicionado
                                <div className="div_btn" style={ { background: showData['default_color'] } } onClick={ ()=>{ setStatus(false); setStatusMenu(false) } }>
                                    Fechar
                                </div>
                            </div>
                        }
                    </div>
                    : null
                }
            </div> 
        )
    }

    function ShowDataUser(type){
        return(
            <div className="div_img_user">
                {
                    GetUserData('id') === 0 ?
                    <>
                        <Svg_User className="icons" color={ type === 1 ? "#ffffff" : showData['default_color'] } />
                        <div className="div_login_or_register">
                            <div className="title" onClick={ ()=>{ OpenLogin('logar', GetUserData('id')) } }>Acesse sua conta</div>
                            <div className="">
                                ou <span className="title" onClick={ ()=>{ OpenLogin('create_user', GetUserData('id')) } }>Cadastre-se</span>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        {
                            userFile != "" ?
                            <div className="icons_" style={ { backgroundImage: 'url("' + userFile + '")' } } onClick={ ()=>{ SetListPag('page', 'dashboard'); } } /> 
                            : 
                            <div className="" onClick={ ()=>{ SetListPag('page', 'dashboard'); } }>
                                <Svg_User className="icons" color={ showData['default_color'] } />
                            </div>
                        }
                        <div className="div_login_or_register" onClick={ ()=>{ SetListPag('page', 'dashboard'); } }>
                            <div className="">Bem vindo(a)</div>
                            <div className="title">{ userName }</div>
                        </div>
                    </>
                }
            </div>
        )
    }

    useEffect(()=>{
        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);

        RegisterPurchasePage('purchase', setShowPurchase);
    }, []);
    
    return(
        <header>
            {/* 
            <div className="div_submenu" style={ { background: showData['default_color'] } }>
                <div className="container">
                    <div className="show_data">
                        <div className="">
                            <a href="#product">Produtos</a>
                        </div>
                        <div className="">
                            <a href="#footer">Onde estamos</a>
                        </div>
                        <div className="div_icons">
                            {
                                showData['facebook'] !='' ?
                                    <div className="width_icons">
                                        <a href={ showData['facebook'] } target="_blank">
                                            <Svg_Facebook className="icons" color="#FFFFFF" />
                                        </a>
                                    </div>
                                : null
                            }
                            
                            {
                                showData['instagram'] !='' ?
                                    <div className="width_icons">
                                        <a href={ showData['instagram'] } target="_blank">
                                            <Svg_Instagram className="icons" color="#FFFFFF" />
                                        </a>
                                    </div>
                                : null
                            }
                            
                            {
                                showData['youtube'] !='' ?
                                    <div className="width_icons">
                                        <a href={ showData['youtube'] } target="_blank">
                                            <Svg_Youtube className="icons" color="#FFFFFF" />
                                        </a>
                                    </div>
                                : null
                            }
                            
                            {
                                showData['tiktok'] !='' ?
                                    <div className="width_icons">
                                        <a href={ showData['tiktok'] } target="_blank">
                                            <Svg_Tiktok className="icons" color="#FFFFFF" />
                                        </a>
                                    </div>
                                : null
                            }
                            
                            {
                                showData['linkedin'] !='' ?
                                    <div className="width_icons">
                                        <a href={ showData['linkedin'] } target="_blank">
                                            <Svg_Linkedin className="icons" color="#FFFFFF" />
                                        </a>
                                    </div>
                                : null
                            }
                            
                            {
                                showData['whatsapp'] !='' ?
                                    <div className="width_icons">
                                        <a href={ "https://wa.me/" + showData['whatsapp'] + "?text=Olá%2C%20gostaria%20de%20mais%20informações." } target="_blank">
                                            <Svg_Whatsapp className="icons" color="#FFFFFF" />
                                        </a>
                                    </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div> 
            */}

            <div className="div_menu" style={ { color: showData['default_color'] } }>
                {
                    status ?
                        <div className="close_purchase" onClick={ ()=>{ setStatus(false) } } />
                    : null
                }
                <div className="container">
                    <div className="show_data">                        
                        <div className="div_logo" onClick={ ()=>{ SetListPag('page', 'index'); } }>
                            <img alt="logo" src={ showData['logo'] } className="logo" />
                        </div>

                        <div className="div_search">
                            <input type="text" style={ { borderColor: showData['default_color'] } } placeholder="Digite aqui oque procura" />
                            <Svg_Search className="icons" color={ showData['default_color'] } />
                        </div>

                        <div className="mobile">
                            <div className="" onClick={ ()=>{ setStatusMenu(true) } }>
                                <Svg_Menu className="icons" color={ showData['default_color'] } />
                            </div>
                            
                            <div className={ statusMenu ? "show_menu menu_active" : "show_menu" } style={ { background: showData['default_color'] } }>                                
                                <div className="div_close_menu">
                                    <div className="" onClick={ ()=>{ setStatusMenu(false) } }>
                                        <Svg_Menu className="icons" color="#ffffff" />
                                    </div>
                                </div>

                                { ShowDataUser(1) } 
                                { ShowPurchase(1) }
                            </div>
                        </div>

                        <div className="div_inf_access">
                            { ShowPurchase(0) }
                            { ShowDataUser(0) }                            
                        </div>
                    </div>

                    <div className="div_search_mobile">
                        <input type="text" style={ { borderColor: showData['default_color'] } } placeholder="Digite aqui oque procura" />
                        <Svg_Search className="icons" color={ showData['default_color'] } />
                    </div>
                </div>
            </div>
        </header>
    )
}