import { useState, useEffect } from "react";

import './Product.css';

import { Svg_AddNewData, Svg_Purchase } from "services/SvgFile";

import { GetDataPage } from "interface/Data";
import { SetListPurchase, SetListPurchaseSingle } from "interface/Purchase";
import { SetModalData, SetModalState } from "interface/PopUp";
import Toast from "components/Toast";

export default function Content_Product(props){

    const [ showData, setShowData ] = useState(props.data);
    const [ showColor, setShowCoolor ] = useState(GetDataPage('settings'));
    const [ qtdProduct, setQtdProduct ] = useState(3);
    const [ status, setStatus ] = useState(false);

    function OpenDetails(title, index, data){
        SetModalData('ShowProduct', { "title": title, "index": index, "data": data });
        SetModalState('ShowProduct', true);
    }

    function OpenToast(){
        setStatus(true);
        setTimeout(() => {
            setStatus(false);
        }, 2000);
    }

    return(
        <div id="product" className="Product">
            {
                status ?
                    <Toast text="Produto adicionado com sucesso!" />
                : null
            }
            <div className="container services_product">
                <div className="title_page">Produtos</div>
                <div className="div_list_data">
                    {
                        showData.map((elem, index)=>{
                            if(index <= qtdProduct){
                                let img = '';
                                let showDataImg = elem.gallery.find(item => item.highlight == true);
                                let showIndex = 0;
                                if(showDataImg){
                                    img = showDataImg.file;
                                    showIndex = elem.gallery.findIndex(item => item.highlight == true);
                                }

                                return(
                                    <div className="div_service_or_product" key={ index }>
                                        <div className="div_img" style={ { backgroundImage: 'url("' + img + '")' } } />
                                        <div className="title">
                                            { elem.title }
                                            <div className="subtitle">{ elem.subtitle }</div>
                                        </div>                                            
                                        <div className="div_price">
                                            {
                                                elem.discount_price === "" || elem.discount_price === "0,00" ?
                                                <div className="discount_price">
                                                    Valor: R$ <span className="bold">{ elem.price }</span>
                                                </div>
                                                :
                                                <>
                                                    <div className="price">
                                                        De: R$ <span className="underline">{ elem.price }</span>
                                                    </div>                                         
                                                    <div className="discount_price" style={ { color: showColor['default_color'] } }>
                                                        Por: R$ { elem.discount_price }
                                                    </div>
                                                </>
                                            }       
                                        </div>
                                        <div className="btn div_details" style={ { borderColor: showColor['default_color'] } } onClick={ ()=>{ OpenDetails(elem.title, showIndex, elem) } }>
                                            <Svg_AddNewData className="icons" color={ showColor['default_color'] } />
                                            <div className="title_btn">Detalhes</div>
                                        </div>
                                        <div className="btn div_purchase" onClick={ ()=>{ SetListPurchaseSingle('add', elem.id, elem); OpenToast() } } style={ { backgroundColor: showColor['default_color'] } }>
                                            <Svg_Purchase className="icons" color="#ffffff" />
                                            <div className="title_btn">Adicionar</div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
                {
                    (qtdProduct + 1) <= showData.length ?
                        <div className="title_page show_add_show_product" style={ { color: showColor['default_color'] } }>
                            <div className="" onClick={ ()=>{ setQtdProduct(qtdProduct + 4) } }>
                                + Ver mais serviços
                            </div>
                        </div>
                    : null
                }
            </div>
        </div>
    )
}