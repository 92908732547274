import { cookieExpirationTime, cookiesRegister, typeCookiePage } from 'fixedData';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

let ListPag = {
    "page" : 'index'
};

let NotifyListPag = {
    "page" : []
}

export function SetListPag(key, value){
    ListPag[key] = value;
    NotifyListPag[key].forEach(element => {
        element(value);
    });

    if(key == 'page'){
        cookies.set(typeCookiePage, value, { path: '/', expires: cookieExpirationTime, domain: cookiesRegister });
    }
}

export function GetListPag(key){
    return ListPag[key];
}

export function RegisterListPag(key, value){
    if(!NotifyListPag[key]){
        NotifyListPag[key] = [];
    }
    NotifyListPag[key].push(value);
}

export function UnRegisterListPag(key, callback){
    if(NotifyListPag[key].length > 0){
        NotifyListPag[key] = NotifyListPag[key].filter((item) => {
            return item !== callback;
        });
    }
}