import { useState, useEffect, useRef } from "react";

import './Brands.css';

import Slider from 'react-slick';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { GetDataPage } from "interface/Data";

export default function Content_Brands(props){

    const width = useRef();
    const [ showData, setShowData ] = useState(props.data);
    const [ showColor, setShowCoolor ] = useState(GetDataPage('settings'));

    const SampleNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div className={ className } style={ { ...style, display: "block", background: "black" } } onClick={ onClick } />
        );
    }

    const SamplePrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
            className={className}
            style={{ ...style, display: "block", background: "black" }}
            onClick={onClick}
            />
        );
    }

    const [ settings, setSettings ] = useState({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    });

    useEffect(()=>{
        AltQtdFile();
        window.addEventListener('resize', function () {
            AltQtdFile();
        });
    }, []);

    function AltQtdFile(){
        if(width?.current){
            if(width?.current.clientWidth > 780){
                setSettings(prevSettings => ({
                    ...prevSettings,
                    slidesToShow: 4
                }));
            }
            
            if(width?.current.clientWidth <= 780 && width?.current.clientWidth >= 630){
                setSettings(prevSettings => ({
                    ...prevSettings,
                    slidesToShow: 3
                }));
            }
            
            if(width?.current.clientWidth < 630 && width?.current.clientWidth >= 500){
                setSettings(prevSettings => ({
                    ...prevSettings,
                    slidesToShow: 2
                }));
            }
            
            if(width?.current.clientWidth < 500){
                setSettings(prevSettings => ({
                    ...prevSettings,
                    slidesToShow: 1
                }));
            }
        }
    } 

    return(
        <div className="Brands">
            <div className="container">
                <div className="title_page">Marcas</div>
                <div className="div_list_data" ref={ width }>
                    <Slider { ...settings }>
                        {
                            showData.map((elem, index) => (
                                <div className="show_brands" key={ index }>
                                    <img className="img_brands" src={ elem.file } alt={ elem.name } />
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </div>
        </div>
    )
}